function toggleMenue() {
	const $menueCheck = document.querySelector('.js-menue-check') as HTMLInputElement;
	if ($menueCheck) {
		$menueCheck.checked = false;
	}
}

/**
 * Close mobile menue whne item is clicked
 */
export function initMenue() {
	const $menueItems = document.querySelectorAll('.js-menue-item') as unknown as HTMLElement[];
	if ($menueItems) {
		for (const $menueItem of $menueItems) {
			$menueItem.addEventListener('click', () => toggleMenue());
		}
	}
}
