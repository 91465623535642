import { initCarousel } from './scripts/carousel';
import { initMenue } from './scripts/menue';
import './styles.scss';

document.addEventListener('DOMContentLoaded', () => {
	const $mapLoaderLink = document.querySelector('.js-load-map');
	$mapLoaderLink?.addEventListener('click', async () => {
		$mapLoaderLink?.parentElement?.parentElement?.classList?.remove('map--idle');
		const { showMap } = await import('./scripts/map');
		showMap();
	});

	initMenue();
	initCarousel({ className: '.js-carousel' as keyof HTMLElementTagNameMap });

	let timeout: number | undefined;
	document.addEventListener('resize', () => {
		window.clearTimeout(timeout);
		setTimeout(() => initCarousel({ className: '.js-carousel' as keyof HTMLElementTagNameMap }), 500);
	});
});
