import Glide from '@glidejs/glide';

import galleryImages from '../responsive-images/gallery/files.json';

interface IInitCarousel {
	className: keyof HTMLElementTagNameMap;
}

function getImagePath(width: number, imageName: string) {
	return `responsive-images/gallery/${width}_${imageName}`;
}

function createImageElement(imageName: string): HTMLElement {
	const $el = document.createElement('img');
	$el.setAttribute(
		'srcset',
		`${getImagePath(600, imageName)} 600w,
			${getImagePath(1024, imageName)} 1024w,
			${getImagePath(1200, imageName)} 1200w,
			${getImagePath(2500, imageName)} 1960w`
	);
	$el.setAttribute('src', getImagePath(400, imageName));
	$el.setAttribute('alt', imageName);
	$el.classList.add('image');
	return $el;
}

function createCarouselSlide($child: HTMLElement): HTMLElement {
	const $el = document.createElement('li');
	$el.classList.add('glide__slide');
	$el.appendChild($child);
	return $el;
}

function createCarouselBullet(id: number): HTMLElement {
	const $el = document.createElement('button');
	$el.classList.add('glide__bullet');
	$el.setAttribute('data-glide-dir', `=${id}`);
	$el.setAttribute('aria-label', `carousel_selector_${id}`);
	return $el;
}

function insertCarouselElements($carousel: HTMLElement): void {
	const $slides = $carousel.querySelector('.js-slides');
	const $bullets = $carousel.querySelector('.js-bullets');

	if (!$slides) {
		return;
	}

	let i = 0;
	for (const imageName of galleryImages) {
		const $img = createImageElement(imageName);
		const $slide = createCarouselSlide($img);
		$slides.appendChild($slide);

		if ($bullets) {
			const $bullet = createCarouselBullet(i);
			$bullets.appendChild($bullet);
		}
		i++;
	}
}

export function initCarousel({ className }: IInitCarousel): void {
	const $glides = document.querySelector(className);
	if ($glides) {
		insertCarouselElements($glides);
		const options: Partial<Glide.Options> = {
			type: 'slider',
		};
		new Glide(className, options).mount();
	} else {
		// eslint-disable-next-line no-console
		console.log('no carousel');
	}
}
